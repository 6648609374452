import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import SimpleDataGrid from './SimpleDataGrid/SimpleDataGrid';

export default function CategoriesDialog({onCancel})  {
  const columns=[
    { headerName: 'ID', field: 'categoryId', width: 70 },
    { headerName: 'Descrizione', field: 'categoryTitle', editable: true, flex:true },
    { headerName: 'Sfondo', field: 'backgroundColor', editable: true, flex:true },
    { headerName: 'Bordo', field: 'borderColor', editable: true, flex:true },
    { headerName: 'Testo', field: 'textColor', editable: true, flex:true },
  ];

  return (
    <Dialog open={true} onClose={onCancel} fullWidth>
      <DialogTitle>Squadre</DialogTitle>
      <DialogContent>
        <SimpleDataGrid
          idField="categoryId"
          getUrl="/categories"
          postUrl="/categories"
          columns={columns}
          onCancel={onCancel}
        />
      </DialogContent>
    </Dialog>
  )
}
