import React from 'react';
import Login from './Login';
import Calendario from './Calendario';
//import ToolBar from './ToolBar'; //<ToolBar title="Calendario" />
import CssBaseline from '@mui/material/CssBaseline';
import {getCookie} from './lib/cookie';

function App() {
  if (!getCookie('authstr'))  return <Login />;

  return (
    <div>
      <CssBaseline/>
      <div style={{padding:5}}>
        <Calendario />
      </div>
    </div>
  );
}

export default App;
