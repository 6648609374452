import React, {useEffect,useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';

export default function EventDialog({event,onSave,onCancel,onDelete,resources,categories})  {
  const [start,setStart]=useState("");
  const [end,setEnd]=useState("");
  const [title,setTitle]=useState("");
  const [resource,setResource]=useState("");
  const [category,setCategory]=useState("");

  useEffect(()=>{
    setTitle(event.title);
    setStart(moment(event.start).format('YYYY-MM-DD HH:mm:ss'));
    setEnd(moment(event.end).format('YYYY-MM-DD HH:mm:ss'));
    if (event.getResources) setResource(event.getResources()[0].id);
    if (event.extendedProps) setCategory(event.extendedProps.category.toString());
  },[]);

  const handleSave=()=>{
    const data={
      id:event.id,
      title,
      start:new Date(start),
      end:new Date(end),
      getResources:()=>[{id:resource}],
      category:category||null
    }
    onSave({event:data});
  }

  const handleDelete=()=>{
    if (!window.confirm('Confermare l\'eliminazione')) return;
    onDelete(event.id);
  }

  return (
    <Dialog open={true} onClose={onCancel}>
      <DialogTitle>Dettagli evento</DialogTitle>
      <DialogContent>
        <TextField value={start} onChange={e=>setStart(e.target.value)} autoFocus margin="dense" name="start" label="Inizio" type="datetime-local"  InputLabelProps={{ shrink: true }} fullWidth  />
        <TextField value={end} onChange={e=>setEnd(e.target.value)} margin="dense" name="end" label="Fine" type="datetime-local"  InputLabelProps={{ shrink: true }} fullWidth  />
        <TextField value={title} onChange={e=>setTitle(e.target.value)} margin="dense" name="title" label="Descrizione" multiline rows={4}  InputLabelProps={{ shrink: true }} fullWidth  />

        <FormControl fullWidth  margin="dense">
          <InputLabel>Squadra</InputLabel>
          <Select value={resource} label="Squadra" onChange={e=>setResource(e.target.value)} error={!resource}>
            {resources.map(r=>
              <MenuItem key={r.id} value={r.id}>{r.title}</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl fullWidth  margin="dense">
          <InputLabel>Categoria</InputLabel>
          <Select value={category} label="Categoria" onChange={e=>setCategory(e.target.value)}>
            <MenuItem value="">--Nessuna--</MenuItem>
            {categories.map(c=>
              <MenuItem key={c.categoryId} value={c.categoryId}>{c.categoryTitle}</MenuItem>
            )}
          </Select>
        </FormControl>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete} color="error">Elimina</Button>
        <Button onClick={onCancel}>Annulla</Button>
        <Button onClick={handleSave} disabled={!resource}>Salva</Button>
      </DialogActions>
    </Dialog>
  )
}
