import React, {useState, useEffect} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import itLocale from '@fullcalendar/core/locales/it';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import api from './lib/api2';
import {deleteCookie} from './lib/cookie';
import moment from 'moment';
import EventDialog from './EventDialog';
import ResourcesDialog from './ResourcesDialog';
import CategoriesDialog from './CategoriesDialog';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';

export default function Calendario()  {
  const [editEvent,setEditEvent]=useState(null);
  const [dialogResources,setDialogResources]=useState(false);
  const [dialogCategories,setDialogCategories]=useState(false);
  const [resources,setResources]=useState([]);
  const [categories,setCategories]=useState([]);

  const getEvents=async (info)=>{
    const start=info.start.toISOString().slice(0,10);
    var end=new Date(info.end);
    end.setDate(end.getDate()+1);
    end=end.toISOString().slice(0,10);

    const url="/events?start="+start+","+end+"|between";
    const r=await api.get(url);
    return r;
  }

  useEffect(()=>{
    api.get("/resources").then(r=>setResources(r));
    api.get("/categories").then(r=>setCategories(r));
  },[]);

  const handleEditEvent=info=>{
    const data={
      id:info.event.id,
      start:moment(info.event.start).format('YYYY-MM-DD HH:mm:ss'),
      end:moment(info.event.end).format('YYYY-MM-DD HH:mm:ss'),
      title:info.event.title,
      resourceId:info.event.getResources()[0].id,
      category:info.event.category
    }

    if (data.id)  {
      api.put("/events",data).then(
        r=>{
          setEditEvent(null);
        },
        err=>alert(err)
      );
    } else {
      delete data.id;
      api.post("/events",data).then(
        r=>{
          setEditEvent(null);
        },
        err=>alert(err)
      );
    }
 }

 const handleDelete=id=>{
   api.delete("/events/"+id).then(
     r=>{setEditEvent(null)},
     err=>alert(err)
   );
 }

  const handleCancelEdit=()=>{
    setEditEvent(null);
  }

  const handleLogoff=()=>{
    if (!window.confirm('Confermi la disconnessione')) return;
    deleteCookie("authstr");
    window.location.reload();
  }

  const handleDateClick=info=>{
    var end=new Date(info.date);
    end.setTime(end.getTime()+60*60*1000)
    setEditEvent({
      id:null,
      start:info.date,
      end:end,
      title:'',
      getResources:()=>[{id:info.resource?info.resource.id:''}],
    })
  }

  const handleEventClick=info=>{
    setEditEvent(info.event);
  }

  const handleResourcesClose=({reload=false})=>{
    setDialogResources(false);
    if (reload) window.location.reload();
  }

  const handleCategoriesClose=({reload=false})=>{
    setDialogCategories(false);
    if (reload) window.location.reload();
  }

  return (
    <div id="io" style={{height:'98vh'}}>
      {editEvent && <EventDialog event={editEvent} onSave={handleEditEvent} onCancel={handleCancelEdit} onDelete={handleDelete} resources={resources} categories={categories} />}
      {dialogResources && <ResourcesDialog  onCancel={handleResourcesClose}  />}
      {dialogCategories && <CategoriesDialog  onCancel={handleCategoriesClose}  />}
      <FullCalendar
        schedulerLicenseKey='0133962397-fcs-1668183848'
        height='100%'
        locale={itLocale}
        events={getEvents}
        plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin ]}
        customButtons={{
          resources:{
            text:'Squadre',
            click:()=>setDialogResources(true)
          },
          categories:{
            text:'Categorie',
            click:()=>setDialogCategories(true)
          },
          logoff:{
            text:'Disconnetti',
            click:handleLogoff
          }
        }}
        headerToolbar={{
          start: 'today prev,next dayGridMonth,resourceTimeGridWeek,resourceTimeGridDay',
          center: 'title',
          end: 'resources categories logoff'
        }}
        resources={resources}
        nowIndicator={true}
        allDaySlot={false}
        initialView="resourceTimeGridWeek"
        scrollTime='08:00:00'
        editable={true}
        datesAboveResources={true}
        eventDrop={handleEditEvent}
        eventResize={handleEditEvent}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
      />
    </div>
  )
}
