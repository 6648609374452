import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import SimpleDataGrid from './SimpleDataGrid/SimpleDataGrid';

export default function ResourcesDialog({onCancel})  {
  const columns=[
    { headerName: 'ID', field: 'id', width: 70 },
    { headerName: 'Descrizione', field: 'title', editable: true, flex:true },
    { headerName: 'Sfondo', field: 'eventBackgroundColor', editable: true, flex:true },
    { headerName: 'Bordo', field: 'eventBorderColor', editable: true, flex:true },
    { headerName: 'Testo', field: 'eventTextColor', editable: true, flex:true },
  ];

  return (
    <Dialog open={true} onClose={onCancel} fullWidth>
      <DialogTitle>Squadre</DialogTitle>
      <DialogContent>
        <SimpleDataGrid
          idField="id"
          getUrl="/resources"
          postUrl="/resources"
          columns={columns}
          onCancel={onCancel}
        />
      </DialogContent>
    </Dialog>
  )
}
