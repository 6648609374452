export function nullZLS(s) {
  if (!s)
    return null;
  return s;
}

export function nullZLSTabella(t_orig) {
  var t=JSON.parse(JSON.stringify(t_orig));
  for (var i=0; i<t.length; i++) {
    for (var key in t[i]) {
      if (t[i][key] === "") {
        t[i][key] = null;
      }
    }
  }
  return t;
}


export function rimuoviNull(t) {
  if (t===null) return '';  //se è un valore singolo ritorna ''

  for (var i=0; i<t.length; i++) {
    for (var key in t[i]) {
      if (t[i][key] === null) {
        t[i][key] = "";
      }
    }
  }
  return t;
}

export function formatFloat(f)	{
	if (isNaN(parseFloat(f)))
		return null;
	return parseFloat(f).toString().replace('.',',');
}
